import React from 'react';
import TopBar from '../TopBar.js';
import BottomNav from '../../fotter/bottomNavigation.js';
import ForYou from './ForYou.js';


function ForYouRoutes() {
  
  return (
    <div>
      <TopBar/>
      <ForYou/>
      <BottomNav />
    </div>
  )
}

export default ForYouRoutes


